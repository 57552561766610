.newsCompanyDiv {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
  height: 100%;
}

.newsCompanyimg {
  background: white;
  border-radius: 17px;
  padding: 15px;
}

.newsDiv {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  font-size: 14px;
}

.newsDiv img {
  border-radius: 20px;
}

.newsDiv h3 {
  font-size: 26px;
}

.newsSidebar h4,
.newDetailSec h3 {
  font-family: "Denk One", sans-serif;
}

.newsSidebar input {
  border: none !important;
  box-shadow: none !important;
}

.newsSidebar .input-group-text {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  border-color: transparent;
  color: white;
  cursor: pointer;
}

.postList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.postList li {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 12px 15px;
  margin-bottom: 20px;
  font-size: 14px;
}

.newDetailSec {
  font-size: 18px;
}

@media screen and (max-width: 1199px) {
  .newDetailSec {
    font-size: 16px;
  }
}