/* home start */
.pt-200 {
  padding-top: 200px;
}

.pt-150 {
  padding-top: 150px;
}

.bannerTitle {
  font-size: 76px;
  font-family: "Denk One", sans-serif;
}

.gradientClr {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bannerP {
  font-size: 18px;
  color: #838383;
}

.socialList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.socialList a {
  background: url('../Images/background/iconBg.png') no-repeat center center;
  background-size: contain;
  display: block;
  padding: 30px;
}

.socialList a img {
  width: 32px;
}

.mainTitle {
  font-size: 42px;
  font-family: "Denk One", sans-serif;
}

.mainTitle2 {
  font-size: 32px;
  display: inline-block;
  font-family: "Denk One", sans-serif;
}

.auditKycDiv>div {
  text-align: center;
  background: url('../Images/background/union.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 0 50px 30px;
  /* height: 300px; */
  height: 85%;
  margin-top: 70px;
}

.auditKycDiv:last-child>div {
  background: url('../Images/background/union2.png') no-repeat center center;
  background-size: 100% 100%;
}

.auditKycDiv img {
  width: 320px;
  margin: -60px 0 20px;
}

.networkTransDiv>div {
  text-align: center;
  background: url('../Images/background/union4.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 30px 50px;
  /* height: 180px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.networkTransDiv:first-child>div {
  background: url('../Images/background/union3.png') no-repeat center center;
  background-size: 100% 100%;
}

.networkTransDiv p {
  font-size: 18px;
}

.usecaseCarousel .owl-item img {
  width: 20px;
  display: inline;
}

.useCaseDiv {
  text-align: center;
  background: url('../Images/background/union4.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 20px 50px 28px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.exchangeDiv {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
  height: 100%;
  /* margin-top: 25px; */
}

.exchangeDiv h3 {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  border-radius: 15px 15px 0 0;
  padding: 15px;
  text-align: center;
  font-family: "Denk One", sans-serif;
}

.exchangeDiv .exchangeBtn {
  margin-bottom: -30px;
}

.icoSec .themeBtn2 {
  background: url('../Images/background/union5.png') no-repeat center center;
  background-size: contain;
}

.icoSec .themeBtn2:last-child {
  background: url('../Images/background/union6.png') no-repeat center center;
  background-size: contain;
}

.icoDates .themeBtn {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}

.partnerSlideDiv {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  border-radius: 20px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.partnerSlideDiv img {
  width: unset !important;
  max-height: 80%;
}

.newsSlideDiv {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.newsSlideDiv img {
  width: unset !important;
  max-height: 70%;
}

.contractAddress {
  background: url('../Images/background/contractBg.png') no-repeat center center;
  background-size: 100% 100%;
  width: fit-content;
  margin: auto;
  padding: 18px 30px;
  font-size: 20px;
  text-align: center;
}

.tokenDiv>div {
  background: url('../Images/background/union3.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 25px 20px;
  font-family: "Denk One", sans-serif;
  text-align: center;
  font-size: 20px;
  height: 100%;
  line-height: 1;
}

.tokenDiv:nth-child(2n+2)>div {
  background: url('../Images/background/union4.png') no-repeat center center;
  background-size: 100% 100%;
}

.contractSlide {
  background: url('../Images/background/contractBg.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 20px 30px;
  max-width: 100%;
  overflow: hidden;
}

.contractSlide ul {
  display: flex;
  max-width: 100%;
  flex-wrap: nowrap;
  gap: 40px;
  animation: tickerAnimation 15s linear infinite;
  margin: 0;
}

.contractSlide li {
  min-width: fit-content;
}

@keyframes tickerAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.roadmapCarousel .owl-item:not(:first-child) .roadArrow {
  color: red;
  filter: grayscale(1);
}

.roadmapCarousel::before {
  content: "";
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 2px;
  border-top: 2px dashed #838383;
  z-index: -1;
}

.roadArrow img {
  width: 32px !important;
  margin: auto;
}

.roadmapTxt {
  background: linear-gradient(45deg, #02180b, #0f1c0d) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
}

.documentSec .themeBtn {
  font-size: 32px;
  width: 300px;
  padding: 25px 10px;
}

.faqAccordion .accordion-item {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  border: none;
  margin-bottom: 20px;
  border-radius: 20px;
  color: white;
}

.faqAccordion .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: white;
  font-weight: 700;
}

.faqAccordion .accordion-button::after {
  display: none;
}

.faqAccordion .accordion-body {
  padding-top: 0;
}

.contactSec .container {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent;
  border-radius: 30px;
  box-shadow: -2px -2px 10px var(--green1), 2px 2px 10px var(--green2);
}

.contactForm input,
.contactForm textarea {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 2px solid transparent !important;
  border-radius: 15px;
  padding: 15px;
  color: white !important;
  box-shadow: none !important;
}

.contactForm input::placeholder,
.contactForm textarea::placeholder {
  color: #838383;
}

/* home end */