/* header start */
.mainNavbar {
  background: #000000a1;
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0px 6px 4px #17171740;
  font-family: "Denk One", sans-serif;
}

.headerLogo,
.footerLogo {
  max-width: 150px;
}

.mainNavbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 18px;
  padding: 5px 0;
  margin: 0 20px;
}

.mainNavbar .navbar-nav .nav-link.active {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.mainNavbar .navbar-nav .nav-link.active::before {
  content: '';
  width: 35px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.mainNavbar .navbar-nav .nav-link.active::after {
  content: '';
  width: 2px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 40px;
  z-index: -1;
}

.mainNavbar .navbar-toggler {
  filter: brightness(0) invert(1);
  border-width: 2px;
  box-shadow: none;
}

.themeBtn,
.themeBtn:hover {
  font-family: "Denk One", sans-serif;
  display: block;
  width: 180px;
  background: url('../Images/background/btnBg.png') no-repeat center center;
  background-size: contain;
  color: #fff;
  font-size: 18px;
  padding: 15px 10px;
  text-align: center;
  border: 0;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.themeBtn.headerBtn,
.themeBtn.headerBtn:hover {
  background: url('../Images/background/btnBg2.png') no-repeat center center;
  background-size: contain;
}

/* header end */

/* footer start */
.footerSec {
  background: linear-gradient(45deg, #08a0451a, #6bbf591a);
}

.footerSocials {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.copyright {
  background: linear-gradient(90deg, var(--green1), var(--green2));
}

.footerLinks h5 {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.footerLinks h5::before {
  content: '';
  width: 60px;
  height: 3px;
  border-radius: 3px;
  background: linear-gradient(90deg, var(--green1), var(--green2));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.footerLinks a {
  display: block;
}

/* footer end */

/* swap header start */
.swapHeaderDiv {
  background: #000;
  position: sticky;
  top: 108px;
  z-index: 99;
}

.swapHeaderDiv .nav-link {
  color: #fff;
}

/* swap header end */

@media screen and (max-width: 1399px) {
  .mainNavbar .navbar-nav .nav-link {
    margin: 0 15px;
  }

  .themeBtn,
  .themeBtn:hover,
  .themeBtn.headerBtn,
  .themeBtn.headerBtn:hover {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 1199px) {
  .mainNavbar .navbar-nav .nav-link {
    margin: 0 10px;
    font-size: 16px;
  }

  .themeBtn,
  .themeBtn:hover,
  .themeBtn.headerBtn,
  .themeBtn.headerBtn:hover {
    font-size: 16px;
    width: 170px;
  }

  .swapHeaderDiv {
    top: 102px;
  }
}

@media screen and (max-width: 991px) {
  .swapHeaderDiv {
    top: 82px;
  }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 575px) {
  .headerLogo {
    max-width: 110px;
  }
}