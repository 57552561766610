@media screen and (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }

  .bannerSec .bannerTitle {
    width: 500px;
  }

  .auditKycDiv>div {
    padding: 0 85px 30px;
    margin-left: 90px;
  }

  .auditKycDiv:last-child>div {
    margin-left: 0;
    margin-right: 90px;
  }

  .abcChainTxt p {
    padding-right: 150px;
  }

  .useCaseDiv {
    padding: 20px 100px 28px;
  }

  .contactSec .container {
    max-width: 1320px;
  }
}

@media screen and (max-width: 1399px) {
  .bannerTitle {
    font-size: 68px;
  }

  .auditKycDiv>div {
    margin-top: 55px;
  }

  .auditKycDiv img {
    width: 290px;
    margin: -52px 0 20px;
  }

  .networkTransDiv .mainTitle2 {
    font-size: 28px;
  }

  .networkTransDiv>div {
    padding: 25px 20px;
  }

  .exchangeDiv h3 {
    font-size: 26px;
    padding: 15px 10px;
  }

  .icoSec .themeBtn2,
  .icoSec .themeBtn2:last-child {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 1199px) {
  .pt-150 {
    padding-top: 120px;
  }

  .mainTitle2 {
    font-size: 28px;
  }

  .bannerTitle {
    font-size: 55px;
  }

  .socialList {
    gap: 20px;
  }

  .socialList a {
    background-size: 100% 100%;
    padding: 15px 27px 20px;
  }

  .auditKycDiv img {
    width: 240px;
    margin: -40px 0 20px;
  }

  .auditKycDiv>div {
    padding: 0 35px 40px;
    margin-top: 45px;
  }

  .networkTransDiv>div {
    padding: 20px;
  }

  .networkTransDiv .mainTitle2 {
    font-size: 25px;
  }

  .networkTransDiv p {
    font-size: 16px;
  }

  .documentSec .themeBtn {
    font-size: 28px;
    width: 250px;
  }

  .exchangeDiv h3 {
    font-size: 22px;
    padding: 12px 8px;
  }

  .icoDates .themeBtn {
    font-size: 15px;
  }

  .partnerSlideDiv,
  .newsSlideDiv {
    height: 65px;
  }

  .contractAddress {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .documentSec .themeBtn {
    font-size: 24px;
    width: 220px;
    padding: 20px 10px;
  }
}

@media screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 90px;
  }

  .mainTitle {
    font-size: 38px;
    font-family: "Denk One", sans-serif;
  }

  .socialList a img {
    width: 25px;
  }

  .contractAddress {
    font-size: 16px;
    padding: 12px 15px 15px;
  }
}

@media screen and (max-width: 575px) {
  .bannerTitle {
    font-size: 45px;
  }

  .bannerP {
    font-size: 16px;
  }

  .mainTitle {
    font-size: 32px;
  }

  .contractAddress {
    font-size: 14px;
  }

  .useCaseDiv {
    padding: 35px 30px 45px;
    height: auto;
  }

  .icoSec .themeBtn,
  .icoSec .themeBtn2 {
    width: 47%;
    max-width: 170px;
  }

  .roadmapTxt .mainTitle2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 425px) {
  .contractAddress {
    font-size: 14px;
    padding: 12px 5px 15px;
  }

  .contractAddress span {
    font-size: 11px;
  }
}