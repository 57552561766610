/* .swapOutletWrapper::after {
  content: '';
  width: 100%;
  height: 200%;
  background: url('../../Assets/Images/background/swapOutletBg.png') no-repeat right top;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: -200px;
} */

.swapMainSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  /* transition: all 0.5s linear; */
}

.swapMainDiv {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  width: 95%;
  max-width: 430px;
  border-radius: 20px;
  /* transition: all 0.5s linear; */
}

.swapTopDiv {
  padding: 20px;
  font-size: 14px;
  border-bottom: 2px solid #ffffffba;
}

.swapBottomDiv {
  padding: 20px;
  font-size: 12px;
  border-top: 2px solid #ffffffba;
}

.swapBottomDiv p {
  margin-bottom: 5px;
}

.swapIcons .btn {
  background: transparent !important;
  border: none;
  filter: brightness(0) invert(1);
  padding: 0;
}

.swapIcons .btn.active {
  filter: none;
}

.swapIcons img {
  width: 24px;
}

.swapMiddleDiv {
  padding: 20px;
}

.swapLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  margin-bottom: 10px;
}

.swapLabel span {
  font-size: 12px;
  font-weight: 400;
}

.swapInput {
  text-align: right;
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  font-size: 14px;
  color: #5C5C5C;
}

.swapInput input {
  text-align: right;
  width: 100%;
  background: transparent;
  border: none !important;
  outline: none;
  font-size: 18px;
  color: black;
}

.swapMiddleDiv .swapBtn {
  background: white !important;
  color: black !important;
  padding: 0;
  border: none !important;
  margin: auto;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  transform: rotate(90deg);
}

.swapMiddleDiv .connectBtn,
.swapModal .connectBtn {
  width: 100%;
  background: white !important;
  color: black !important;
  border: none !important;
  border-radius: 10px;
  font-weight: 700;
  padding: 10px;
}

.swapMiddleDiv .connectBtn:disabled,
.swapModal .connectBtn:disabled {
  color: #B0B0B0 !important;
}

.swapModal .connectBtn.cancelBtn {
  background: #555555 !important;
  color: white !important;
  font-weight: 600;
}

.swapLeftDiv {
  background: white;
  border-radius: 20px;
  padding: 20px;
  color: black;
  flex: 1;
  max-width: 95%;
}

/* selectToken modal */
.swapModal .tokenSearch {
  box-shadow: none !important;
  border-radius: 10px;
  padding: 10px;
}

.commonTokenDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.commonTokenDiv button.btn {
  display: flex;
  align-items: center;
  gap: 6px;
  background: white;
  color: black;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  font-weight: 600;
  padding: 8px 10px;
}

.commonTokenDiv button.btn.selected {
  background: #838383;
}

.commonTokenDiv button.btn.selected,
.tokenLi.selected {
  color: #555555;
  opacity: 1;
}

.commonTokenDiv button.btn.selected img,
.tokenLi.selected img {
  filter: grayscale(0.5);
}

.tokenLi {
  display: flex;
  align-items: center;
  gap: 10px;
}

.swapLabel img,
.commonTokenDiv img,
.tokenLi img {
  width: 24px;
}

.swapModal .manageTokenBtn {
  display: block;
  background: transparent !important;
  border: none;
  box-shadow: none;
  margin: auto;
  color: black !important;
  font-weight: 600;
}

/* manageToken modal */
.manageBtns button.btn {
  background: #555555 !important;
  color: white !important;
  border: none;
  box-shadow: none;
  border-radius: 20px;
}

.manageBtns button.btn.active {
  background: white !important;
  color: black !important;
  font-weight: 600;
}

.manageSwitch {
  display: flex;
  gap: 10px;
  align-items: center;
}

.manageSwitch {
  border: 1px solid white;
  border-radius: 20px;
  padding: 20px;
}

.manageSwitch .form-switch .form-check-input,
.manageSwitch .form-switch .form-check-input:focus,
.slippageSwitch .form-switch .form-check-input,
.slippageSwitch .form-switch .form-check-input:focus {
  --bs-form-switch-bg: url('../../Assets/Images/icons/switchOff2.png');
  border: none;
  box-shadow: none;
  width: 72px;
  height: 40px;
  border: 4px solid white;
  background-color: white;
}

.manageSwitch .form-switch .form-check-input:checked,
.slippageSwitch .form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url('../../Assets/Images/icons/switchOn.png');
  border: 5px solid black;
  background-color: black;
}

/* slippageSetting modal */
.transSpeed button.btn {
  background: white !important;
  color: #838383 !important;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.transSpeed button.btn.active {
  color: black !important;
}

.slippageSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slippageTag {
  background: black;
  border-radius: 20px;
  width: 56px;
  height: 32px;
  padding: 3px;
  text-align: center;
}

.slippageSwitch .form-switch .form-check-input,
.slippageSwitch .form-switch .form-check-input:focus {
  width: 56px;
  height: 32px;
}

/* expertMode modal */
.expertWarning {
  background: white;
  color: black;
  display: flex;
  gap: 15px;
  border-radius: 20px;
  padding: 20px;
  font-weight: 500;
}

.expertWarning span {
  color: #FFD53F;
  font-size: 20px;
}