@import url('https://fonts.googleapis.com/css2?family=Denk+One&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --green1: #08A045;
  --green2: #6BBF59;
}

body {
  color: #fff;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #000;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}

.outletWrapper {
  /* min-height: 100vh; */
  position: relative;
  padding-bottom: 150px;
}

.outletWrapper::before {
  content: '';
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 200px);
  background: url('../Images/background/outletBg.png') repeat-y center top;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: 200px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin-bottom: 10px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

ul {
  list-style-type: disc;
}

button:disabled {
  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* pagination */
.userPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #838383;
  font-weight: 500;
}

.userPagination .paginationDisabled {
  opacity: 0.5;
}

/* custom modal */
.customModal .modal-header {
  padding: 18px 25px;
}

.customModal .modal-body {
  padding: 25px;
}

.customModal .modal-content {
  background: linear-gradient(90deg, var(--green1), var(--green2));
  border-radius: 20px;
}

.customModal .modal-title {
  font-size: 20px;
  font-weight: 600;
}

.customModal .btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
  padding: 0;
  margin-right: 0;
}