.ecoBriefDiv {
  background: linear-gradient(black, black) padding-box, linear-gradient(90deg, var(--green1), var(--green2)) border-box;
  border: 1px solid transparent;
  border-bottom-width: 4px;
  padding: 20px 20px 10px;
  text-align: center;
  border-radius: 20px;
  height: 100%;
}

.ecoBriefDiv img {
  max-height: 100px;
  margin-bottom: 20px;
}

.ecoBriefDiv h4 {
  font-family: "Denk One", sans-serif;
}