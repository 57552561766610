.aboutBannerSec {
  background: url('../Images/background/aboutBanner.png') no-repeat left bottom;
  background-size: cover;
  padding: 90px;
}

.aboutDiv>div {
  background: url('../Images/background/union.png') no-repeat center center;
  background-size: 100% 100%;
  padding: 40px 30px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutDiv:last-child>div {
  background: url('../Images/background/union2.png') no-repeat center center;
  background-size: 100% 100%;
}

.aboutDiv .missionImg {
  /* width: 200px; */
}

@media screen and (min-width: 1600px) {
  .aboutDiv>div {
    padding: 40px 50px;
  }
}

@media screen and (max-width: 991px) {
  .aboutBannerSec {
    padding: 70px;
  }
}

@media screen and (max-width: 767px) {
  .aboutDiv>div {
    padding: 40px 30px;
  }
}